import { red, yellow, orange } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: yellow[300],
      main: yellow[600],
      dark: yellow[800]
    },
    secondary: {
      light: orange[300],
      main: orange[600],
      dark: orange[800]
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#282c34',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        padding: '20px 10px',
        margin: '10px',
        backgroundColor: '#fff', // 5d737e
      },
    },
    MuiButton: {
      root: {
      	width: '150px',
        margin: '5px',
        borderRadius: 18,
      },
    },
  },
});
export default theme;